import { useMemo } from 'react';

import { useBasic } from 'contexts';
import { ContentBox, Hero, NucleosDeAtuacao } from 'components';

import './style.scss';
import ImageHero from 'assets/images/hero-quem-faz-a-ucom.jpg';
import ImageHumanograma from 'assets/images/quem-faz-a-ucom/humanograma.png';
import ImageFornecedores from 'assets/images/quem-faz-a-ucom/fornecedores.jpg';

export default function QuemFazAUcomPage() {
  const { width } = useBasic();
  const colorTheme = '#E4F998';

  return (
    <>
      <Hero
        colorTheme={colorTheme}
        title={
          <>
            Quem faz a <br />
            UCOM
          </>
        }
        description="Conheça a rede de parceiros, os estados e a equipe de colaboradores da Unidade de Comunicação do Sebrae Nacional. De 2019 a 2022, essa equipe trabalhou para ajudar a construir um país onde pequenos negócios tenham cada vez mais chances de sucesso."
        image={<img src={ImageHero} alt=" " />}
      />

      <ContentBox className="pt-8 lg:pt-16 lg:!px-0">
        <div className="w-full max-w-[1040px] mx-auto mb-9 lg:mb-16">
          <p className="text-lg lg:text-[28px] font-normal lg:leading-snug text-rich-black block">
            <strong className="font-campuni-bold font-normal">
              A comunicação do Sebrae Nacional foi construída por meio de uma atuação conjunta entre as áreas de comunicação do
              Sebrae nos estados, os colaboradores da comunicação do Sebrae Nacional e sua rede de fornecedores. Todos trabalharam
              de 2019 a 2022 para ajudar a construir um país onde mais pequenos negócios tenham chance de dar certo :)
            </strong>
          </p>
        </div>

        <img src={ImageHumanograma} alt=" " className="w-full block max-w-[1171px] mx-auto lg:mb-24" />

        {width < 1024 ? (
          <a
            href={`${process.env.PUBLIC_URL}/img/organograma.png`}
            target="_blank"
            rel="noopener noreferrer"
            className={`link-download-orcamento rounded-md p-4 leading-none inline-flex items-center font-campuni-bold font-normal text-lg mb-12`}
            download
          >
            Faça o download do organograma gratuito
          </a>
        ) : null}

        <div className="quem-faz-fornecedores relative w-full flex justify-center">
          <div className="w-full max-w-[1280px] mx-auto py-8">
            <div className="data flex items-start justify-end relative">
              <img src={ImageFornecedores} alt=" " className="relative z-10" />

              <div className="relative z-10">
                <h3>Fornecedores:</h3>

                <div className="space-y-8 lg:space-y-0">
                  <ul className="lg:w-[380px] lg:mr-8 space-y-8 lg:space-y-10">
                    <li>
                      <p>
                        <strong>Imprensa</strong>
                      </p>
                      <p>Máquina de Notícias</p>
                      <p>Poder 360</p>
                      <p>Torre Comunicação</p>
                      <p>Agência Estado</p>
                      <p>My Clipp</p>
                      <p>Amplex/Fotoware</p>
                    </li>

                    <li>
                      <p>
                        <strong>Canal Empreender</strong>
                      </p>
                      <p>Band/Newco</p>
                    </li>

                    <li>
                      <p>
                        <strong>Comunicação Interna</strong>
                      </p>
                      <p>TOTVS</p>
                    </li>

                    <li>
                      <p>
                        <strong>Publicidade</strong>
                      </p>
                      <p>Artplan</p>
                      <p>Nova S/B</p>
                      <p>DeBrito</p>
                    </li>

                    <li>
                      <p>
                        <strong>Central de Produção</strong>
                      </p>
                      <p>Potenza (em fase de homologação)</p>
                      <p>M Ribeiro</p>
                      <p>Cine Group</p>
                    </li>
                  </ul>

                  <ul className="lg:w-[240px] space-y-8 lg:space-y-10">
                    <li>
                      <p>
                        <strong>Redes Sociais</strong>
                      </p>
                      <p>Moringa</p>
                      <p>FSB Loures</p>
                    </li>

                    <li>
                      <p>
                        <strong>Digital/BI</strong>
                      </p>
                      <p>Moringa</p>
                      <p>Pipefy</p>
                    </li>

                    <li>
                      <p>
                        <strong>Live Marketing</strong>
                      </p>
                      <p>Monumenta</p>
                      <p>Nosotros</p>
                    </li>

                    <li>
                      <p>
                        <strong>Branding</strong>
                      </p>
                      <p>Superunion</p>
                    </li>

                    <li>
                      <p>
                        <strong>Design</strong>
                      </p>
                      <p>Shutterstock</p>
                      <p>Soundcloud</p>
                      <p>Wetransfer</p>
                      <p>Envato</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ContentBox>

      <NucleosDeAtuacao colorTheme={colorTheme} />
    </>
  );
}
