import React, { useMemo } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, FreeMode, Swiper as SwiperType } from 'swiper';

import { useBasic } from 'contexts';
import { Anchor, ContentBox, FlexibleBox, Hero, LinkButton, NucleosDeAtuacao, TitleArea } from 'components';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './style.scss';
import ImageHero from 'assets/images/hero-redes-sociais.jpg';
import IcoLinkedin from 'assets/images/ico-colored-linkedin.png';
import IcoFacebook from 'assets/images/ico-colored-facebook.png';
import IcoInstagram from 'assets/images/ico-colored-instagram.png';
import IcoYoutube from 'assets/images/ico-colored-youtube.png';
import IcoTwitter from 'assets/images/ico-colored-twitter.png';
import ImageReviravoltas from 'assets/images/redes-sociais/reviravoltas.png';

export default function RedesSociaisPage() {
  const { width } = useBasic();
  const colorTheme = '#CD5BE8';

  const pagination = {
    clickable: true,
    renderBullet: function (index: number, className: string) {
      return '<span class="pagination-ico ' + className + '"></span>';
    },
  };

  const boxes: JSX.Element[] = [
    <div
      className={`rounded-md border-2 border-[#CD5BE8] py-6 px-5 flex flex-col justify-between bg-cultured text-rich-black space-y-5 h-[350px] lg:h-auto`}
    >
      <p className={`text-center text-3xl text-[#CD5BE8] font-normal block`}>
        <strong className="font-campuni-bold font-normal">2019</strong>
      </p>

      <p className="text-lg font-campuni-regular font-normal">8.117 conteúdos publicados</p>

      <p className="text-lg font-campuni-regular font-normal">2.995.278 de ações de engajamento</p>

      <p className="text-lg font-campuni-regular font-normal">16.687 respostas às interações dos seguidores</p>

      <p className={`text-lg font-normal text-[#CD5BE8]`}>
        <strong className="font-campuni-bold font-normal">Crescimento de 30,7% no número dos seguidores</strong>
      </p>
    </div>,

    <div
      className={`rounded-md border-2 border-[#CD5BE8] py-6 px-5 flex flex-col justify-between bg-cultured text-rich-black h-[350px] lg:h-auto`}
    >
      <p className={`text-center text-3xl text-[#CD5BE8] font-normal block`}>
        <strong className="font-campuni-bold font-normal">2020*</strong>
      </p>

      <p className="text-lg font-campuni-regular font-normal">9.452 conteúdos publicados</p>

      <p className="text-lg font-campuni-regular font-normal">3.647.169 de ações de engajamento</p>

      <p className="text-lg font-campuni-regular font-normal">33.433 respostas às interações dos seguidores</p>

      <p className={`text-lg font-normal text-[#CD5BE8]`}>
        <strong className="font-campuni-bold font-normal">Crescimento de 27,5% no número dos seguidores</strong>
      </p>
    </div>,

    <div
      className={`rounded-md border-2 border-[#CD5BE8] py-6 px-5 flex flex-col justify-between bg-cultured text-rich-black h-[350px] lg:h-auto`}
    >
      <p className={`text-center text-3xl text-[#CD5BE8] font-normal block`}>
        <strong className="font-campuni-bold font-normal">2021</strong>
      </p>

      <p className="text-lg font-campuni-regular font-normal">9.170 conteúdos publicados</p>

      <p className="text-lg font-campuni-regular font-normal">2.058.744 de ações de engajamento</p>

      <p className="text-lg font-campuni-regular font-normal">38.844 respostas às interações dos seguidores</p>

      <p className={`text-lg font-normal text-[#CD5BE8]`}>
        <strong className="font-campuni-bold font-normal">Crescimento de 6,5% no número dos seguidores</strong>
      </p>
    </div>,

    <div
      className={`rounded-md border-2 border-[#CD5BE8] py-6 px-5 flex flex-col justify-between bg-cultured text-rich-black h-[350px] lg:h-auto`}
    >
      <p className={`text-center text-3xl text-[#CD5BE8] font-normal block`}>
        <strong className="font-campuni-bold font-normal">2022</strong>
      </p>

      <p className="text-lg font-campuni-regular font-normal">5.818 conteúdos publicados</p>

      <p className="text-lg font-campuni-regular font-normal">1.902.370 ações de engajamento</p>

      <p className="text-lg font-campuni-regular font-normal">54.230 respostas às interações dos seguidores</p>

      <p className={`text-lg font-normal text-[#CD5BE8]`}>
        <strong className="font-campuni-bold font-normal">Crescimento de 14,2% no número dos seguidores</strong>
      </p>
    </div>,
  ];

  return (
    <>
      <Hero
        colorTheme={colorTheme}
        title="Redes Sociais"
        description="Responsável pela gestão e monitoramento das redes sociais, da voz do Sebrae nas mídias sociais e dos contratos das agências digitais."
        image={<img src={ImageHero} alt=" " />}
      />

      <ContentBox className="pt-8 lg:pt-16 space-y-8 lg:space-y-16" colorTheme={colorTheme} watermark>
        <FlexibleBox colorTheme={colorTheme} title={<>Evolução da atuação a cada ano: 2019-2022</>} position={'start'}>
          <div className="space-y-8">
            <div>
              <p>
                A estratégia ao longo dos anos possibilitou o crescimento das redes sociais, gerando maior visibilidade e número
                de seguidores
              </p>
            </div>

            <div>
              <p className="mb-2">Número de seguidores por rede:</p>
              <p className="flex items-center space-x-4 mb-4">
                <span className="w-[15px] h-[15px] flex items-center justify-center">
                  <img src={IcoLinkedin} alt=" " className="block w-full h-full object-contain" />
                </span>
                <span>LinkedIn: 2.993.592</span>
              </p>
              <p className="flex items-center space-x-4 mb-4">
                <span className="w-[15px] h-[15px] flex items-center justify-center">
                  <img src={IcoFacebook} alt=" " className="block w-full h-full object-contain" />
                </span>
                <span>Facebook: 2.192.202</span>
              </p>
              <p className="flex items-center space-x-4 mb-4">
                <span className="w-[15px] h-[15px] flex items-center justify-center">
                  <img src={IcoInstagram} alt=" " className="block w-full h-full object-contain" />
                </span>
                <span>Instagram: 1.946.471</span>
              </p>
              <p className="flex items-center space-x-4 mb-4">
                <span className="w-[15px] h-[15px] flex items-center justify-center">
                  <img src={IcoYoutube} alt=" " className="block w-full h-full object-contain" />
                </span>
                <span>YouTube: 725.738</span>
              </p>
              <p className="flex items-center space-x-4 mb-4">
                <span className="w-[15px] h-[15px] flex items-center justify-center">
                  <img src={IcoTwitter} alt=" " className="block w-full h-full object-contain" />
                </span>
                <span>Twitter: 439.851</span>
              </p>
              <p>
                <strong className="font-campuni-bold font-normal">Total de seguidores: 8.297.854</strong>
              </p>
            </div>

            <div>
              <p className="block mb-2">
                <strong className="font-campuni-bold font-normal">Crescimento no número de seguidores</strong>
              </p>
              <p>
                de 4.006.668 <br />
                para 8.297.854 <br />
                <strong className="font-campuni-bold font-normal">+ 107,1%</strong>
              </p>
            </div>

            <div>
              <p className="block mb-2">
                <strong className="font-campuni-bold font-normal">Conteúdos publicados</strong>
              </p>
              <p>
                <strong className="font-campuni-bold font-normal">32.557</strong>
              </p>
            </div>

            <div>
              <p className="block mb-0">
                <strong className="font-campuni-bold font-normal">Ações de engajamento </strong>
              </p>
              <p className="block mb-2">(curtidas, compartilhamentos, comentários sic)</p>
              <p>
                <strong className="font-campuni-bold font-normal">10.603.561</strong>
              </p>
            </div>

            <div>
              <p className="block mb-2">
                <strong className="font-campuni-bold font-normal">Respostas aos seguidores</strong>
              </p>
              <p>
                <strong className="font-campuni-bold font-normal">143.194</strong>
              </p>
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox
          colorTheme={colorTheme}
          title={<>Definição de linhas editoriais estratégicas para cada rede social</>}
          position={'end'}
        >
          <div className="space-y-6">
            <div>
              <p className="mb-2">
                No início de 2019, o Sebrae Nacional tinha 4,1 milhões de seguidores. Em menos de quatro anos, ultrapassamos a
                marca dos <strong className="font-campuni-bold font-normal">8 milhões</strong>, números que representam muito.
              </p>
              <p className="mb-2">
                Em 2021, mais de 80 mil pessoas acompanharam as lives realizadas nos canais do Sebrae Nacional
              </p>
              <p className="">Em 2022, ano do cinquentário, a marca está presente em cinco plataformas. </p>
            </div>

            <div>
              <p className="mb-2 text-base lg:text-xl">
                <strong className="font-campuni-bold font-normal">Facebook</strong>
              </p>
              <p>O foco é orientar com informações práticas para os empreendedores.</p>
            </div>

            <div>
              <p className="mb-2 text-base lg:text-xl">
                <strong className="font-campuni-bold font-normal">Instagram</strong>
              </p>
              <p>Inspirar com histórias de sucesso e conteúdos motivacionais.</p>
            </div>

            <div>
              <p className="mb-2 text-base lg:text-xl">
                <strong className="font-campuni-bold font-normal">Linkedin</strong>
              </p>
              <p>
                Aprofundar e especializar, com artigos e análises sobre negócios. A página do Sebrae no LinkedIn, com 2,9 milhões
                de seguidores, é a sexta maior do Brasil.
              </p>
            </div>

            <div>
              <p className="mb-2 text-base lg:text-xl">
                <strong className="font-campuni-bold font-normal">Twitter</strong>
              </p>
              <p>
                O perfil @sebrae tem atuação integrada à nova Agência Sebrae de Notícias, e seu foco é atualizar o seguidor com
                informações e coberturas em tempo real.
              </p>
            </div>

            <div>
              <p className="mb-2 text-base lg:text-xl">
                <strong className="font-campuni-bold font-normal">Youtube</strong>
              </p>
              <p>O foco é informar e entreter com vídeos educativos e lives em formato de tira-dúvidas.</p>
            </div>
          </div>
        </FlexibleBox>

        <TitleArea className="!pb-12 !pt-16 text-center">Destaques</TitleArea>

        <div className="!mt-0">
          {width >= 1024 ? (
            <div className="flex items-stretch justify-between space-x-[18px]">
              {boxes.map((x: JSX.Element, index) => (
                <React.Fragment key={index + 2}>{x}</React.Fragment>
              ))}
            </div>
          ) : (
            <>
              <Swiper
                pagination={pagination}
                slidesPerView={1}
                spaceBetween={0}
                modules={[Pagination, FreeMode]}
                className="mySwiper swiper-mobo w-[274px] mx-auto"
                style={{ '--color-theme': colorTheme } as React.CSSProperties}
              >
                {boxes.map((x: JSX.Element, index) => (
                  <SwiperSlide key={index + 3}>{x}</SwiperSlide>
                ))}
              </Swiper>
            </>
          )}

          <p className="block mt-4 text-black text-xs lg:text-base font-campuni-regular">
            *Os números de 2020 estão fora dos padrões em decorrência da pandemia de Covid-19. Em 2022, conquistamos um
            engajamento médio (publicações/ações de engajamento) 45% superior em relação a 2021.
          </p>
        </div>

        <FlexibleBox colorTheme={colorTheme} title={<>Expedição Aqui Tem Sebrae</>} position={'start'}>
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Período</strong>
              </p>
              <p>Out/18-fev/20</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Síntese do assunto</strong>
              </p>
              <p>
                Realização de entrevistas presenciais com empreendedores e empreendedoras atendidos pelo Sebrae em 17 UFs (Piauí,
                Santa Catarina, Goiás, São Paulo, Pará, Acre, Sergipe, Rio Grande do Norte, Rio Grando do Sul, Espírito Santo,
                Amazonas, Roraima, Alagoas, Pernambuco, Ceará, Minas Gerais e Distrito Federal).
              </p>
            </div>

            <div>
              <p>
                A Expedição Aqui Tem Sebrae foi um desdobramento, nas redes sociais, da campanha publicitária de mesmo nome. O
                projeto, que percorreria todas as UFs, foi interrompido pela pandemia. Os objetivos da expedição eram ressaltar a
                capilaridade da rede de atendimento do Sebrae e mostrar histórias inspiradoras e de sucesso.
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Resultado</strong>
              </p>
              <p>
                A Expedição Aqui Tem Sebrae deu visibilidade a mais de 60 pequenos negócios de todo o Brasil. <br />
                Os conteúdos da expedição alcançaram 3.306.948 de contas únicas, tiveram 806.078 visualizações e 92.761 ações de
                engajamento.
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Ponto Focal</strong>
              </p>
              <p>Ana Canêdo</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Materiais ilustrativos</strong>
              </p>

              {width >= 1024 ? (
                <>
                  <LinkButton colorTheme={colorTheme} url={'https://sebrae.com.br/aquitem'}>
                    Acesse
                  </LinkButton>
                </>
              ) : (
                <div className="w-box-full flex-col flex items-center justify-center">
                  <LinkButton colorTheme={colorTheme} url={'https://sebrae.com.br/aquitem'}>
                    Acesse
                  </LinkButton>
                </div>
              )}
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox colorTheme={colorTheme} title={<>Série especial #SebraePeloBrasil</>} position={'end'}>
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Período</strong>
              </p>
              <p>Fev-nov/21</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Síntese do assunto</strong>
              </p>
              <p>
                Realização de entrevistas online com clientes atendidos pelo Sebrae, empreendedores que são referência no mercado
                e especialistas nos mais diversos assuntos.
                <br />
                No ano de 2021, estruturamos a agenda de lives com foco em produzir conteúdos para informar, tirar dúvidas e
                motivar os seguidores.
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Resultado</strong>
              </p>
              <p>Em 2021, 70.431 seguidores participaram ao vivo de 127 lives ao longo do ano.</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Pontos Focais</strong>
              </p>
              <p>Ana Canêdo e Carolina Guedes</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Materiais ilustrativos</strong>
              </p>

              {width >= 1024 ? (
                <>
                  <LinkButton
                    colorTheme={colorTheme}
                    url={'https://www.youtube.com/watch?v=R_d892paDZ4&list=PLnPmdlI4EGt1hNzitzbaMxDBTVbRoDRff'}
                  >
                    Acesse
                  </LinkButton>
                </>
              ) : (
                <div className="w-box-full flex-col flex items-center justify-center">
                  <LinkButton
                    colorTheme={colorTheme}
                    url={'https://www.youtube.com/watch?v=R_d892paDZ4&list=PLnPmdlI4EGt1hNzitzbaMxDBTVbRoDRff'}
                  >
                    Acesse
                  </LinkButton>
                </div>
              )}
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox colorTheme={colorTheme} title={<>Expedição Sebrae 50+50</>} position={'start'}>
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Período</strong>
              </p>
              <p>Jan-ago/22</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Síntese do assunto</strong>
              </p>
              <p>
                Produção de filmes sobre as histórias de empreendedores e empreendedoras de 12 UFs (Distrito Federal, Rondônia,
                Sergipe, Espírito Santo, Santa Catarina, Mato Grosso do Sul, Amapá e Maranhão, todos já veiculados. Até dezembro,
                serão publicados os filmes de Rio de Janeiro, Paraná, Tocantins e Ceará).
              </p>
            </div>

            <div>
              <p>
                Foram selecionadas as UFs que não participaram da expedição anterior (presencialmente) e/ou não tiveram vídeos
                recentes produzidos. O roteiro dos filmes mostrava o passado, presente e futuro das histórias de dificuldades e
                superação. O mote foi bem alinhado à campanha de celebração dos 50 anos do Sebrae.
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Resultado</strong>
              </p>
              <p>
                A Expedição Sebrae 50+50 (até 20/09/2022) teve 1.812.000 de visualizações em oito filmes. O conteúdo foi
                impulsionado no YouTube e veiculado na televisão (canal SBT).
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Ponto Focal</strong>
              </p>
              <p>Ana Canêdo </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Materiais ilustrativos</strong>
              </p>

              {width >= 1024 ? (
                <>
                  <LinkButton
                    colorTheme={colorTheme}
                    url={'https://www.youtube.com/playlist?list=PLnPmdlI4EGt2dQNrLiYk9NyX1gH3_14QH'}
                  >
                    Acesse
                  </LinkButton>
                </>
              ) : (
                <div className="w-box-full flex-col flex items-center justify-center">
                  <LinkButton
                    colorTheme={colorTheme}
                    url={'https://www.youtube.com/playlist?list=PLnPmdlI4EGt2dQNrLiYk9NyX1gH3_14QH'}
                  >
                    Acesse
                  </LinkButton>
                </div>
              )}
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox colorTheme={colorTheme} title={<>História em quadrinhos "Reviravoltas"</>} position={'end'}>
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Período</strong>
              </p>
              <p>Ago-set/22</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Síntese do assunto</strong>
              </p>
              <p>
                História em quadrinhos publicada em formato de série com dez episódios. Por meio de ilustrações, com uma linguagem
                leve e descontraída, a série conta a trajetória (fictícia) da empreendedora Aline e as reviravoltas da vida de
                quem empreende.
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Resultado</strong>
              </p>
              <p>
                O projeto especial da história em quadrinhos "Reviravoltas" alcançou 1.195.879 pessoas através do Feed do
                Instagram, 1.412.515 através dos Stories e gerou 277.908 ações de engajamento.
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Ponto Focal</strong>
              </p>
              <p>Carolina Guedes</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-3">
                <strong className="font-campuni-bold font-normal">Materiais ilustrativos</strong>
              </p>

              {width >= 1024 ? (
                <>
                  <img src={ImageReviravoltas} alt=" " className="block mb-2" />

                  <LinkButton
                    colorTheme={colorTheme}
                    url={'https://www.instagram.com/sebrae/guide/reviravoltas/17940290618100270'}
                  >
                    Acesse
                  </LinkButton>
                </>
              ) : (
                <div className="w-box-full flex-col flex items-center justify-center">
                  <img src={ImageReviravoltas} alt=" " className="block max-w-[150px] w-full mb-6" />

                  <LinkButton
                    colorTheme={colorTheme}
                    url={'https://www.instagram.com/sebrae/guide/reviravoltas/17940290618100270'}
                  >
                    Acesse
                  </LinkButton>
                </div>
              )}
            </div>
          </div>
        </FlexibleBox>
      </ContentBox>

      <NucleosDeAtuacao colorTheme={colorTheme} />
    </>
  );
}
